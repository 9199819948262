<template>
  <div>launch</div>
</template>

<script>
  export default {
    name: 'Launch',
  }
</script>

<style lang="less" scoped></style>
